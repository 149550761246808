define(['app'], function(app) {

  

  var responsiveThreeLevelNav = function() {
    var self = this;
    self.app = app;

    var _config = {
      subnavSelector: '[data-js-element=subnav]',
      subnavLinkSelector: '* > [data-js-element=subnavLink]'
    };

    var _init = function(element) {
      self.element = element;
      self.subnavs = self.element.querySelectorAll(self.config.subnavSelector);
      self.mobileNavMenuStatus = 'closed';

      for (var i = 0; i < self.subnavs.length; i++) {
        self.subnavs[i].setAttribute('data-subnav-index', i);
        self.subnavs[i].addEventListener('click', self.triggerDropdown);

        if (i === (self.subnavs.length - 1)) {
          self.allSubnavLinksFound = true;
        }
      }

      app.subscribe('simpleResponsiveHeader/mobileNavMenuStatus', function(mobileNavMenuStatus) {
        self.mobileNavMenuStatus = mobileNavMenuStatus;
      });

      app.subscribe('yongeHeader/mobileNavMenuStatus', function(mobileNavMenuStatus) {
        self.mobileNavMenuStatus = mobileNavMenuStatus;
      });

      return self;
    };

    var _findSubnavLink = function(el) {
      while (el.getAttribute('data-js-element') !== 'subnav') {
        if (el.getAttribute('data-js-element') === 'subnavLink') {
          break;
        }
        el = el.parentNode;
      }
      return el;
    };

    var _triggerDropdown = function(event) {
      var link = _findSubnavLink(event.target);
      event.stopPropagation();

      if (link.getAttribute('data-js-element') !== 'subnavLink' || self.mobileNavMenuStatus === 'closed') {
        return;
      }
      event.preventDefault();

      var modClass;
      if (self.allSubnavLinksFound) {
        var index = this.getAttribute('data-subnav-index');
        var subnavLinks = self.subnavs[index].querySelectorAll(self.config.subnavLinkSelector);

        for (var i = 0; i < subnavLinks.length; i++) {
          var subnavlink = subnavLinks[i];
          if (subnavlink !== link) {
            modClass = subnavlink.getAttribute('data-expand-class');
            self.app.element.removeClass(modClass, subnavlink);
          }
        }
        modClass = link.getAttribute('data-expand-class');
        if (self.app.element.hasClass(modClass, link)) {
          self.app.element.removeClass(modClass, link);
        } else {
          self.app.element.addClass(modClass, link);
        }
      }
    };

    self.config = _config;
    self.init = _init;
    self.triggerDropdown = _triggerDropdown;
  };

  return responsiveThreeLevelNav;
});
